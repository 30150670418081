import React from 'react';
import Header from '../components/HeaderComponent';
import PrivacyPolicy from '../components/PrivacyPolicyComponent';
import Footer from '../components/FooterComponent';

import MainPageMetadata from '../metadata/MainPageMetadata';

function PrivacyPolicyPage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <Header />
            <PrivacyPolicy />
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;
